import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

function doAutoExpand(e) {
  const field = e.target;
	field.style.height = 'inherit';
	field.style.height = field.scrollHeight + 'px';
};

const TextArea = (props) => {
  const { label, input, type, required, hint, placeholder, classes, rows,
          autoExpand, meta: { error, warning, touched }, labelClassName } = props;

  return (
    <div className={`FormRow ${classes}`}>
      {label &&
        <label
          className={cn({ FormLabel: true, 'FormLabel--required': required }, labelClassName)}
          htmlFor={input.name}
        >
          {label}
        </label>
      }

      <textarea
        {...input}
        rows={rows}
        id={input.name}
        placeholder={placeholder}
        onInput={autoExpand ? doAutoExpand : null}
        className="TextArea"
      />

      {hint &&
        <div className="hint">
          {hint}
        </div>
      }

      { touched && (error || warning) &&
        <div className="ErrorExplanation">
          {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
      }
    </div>
  );
};

TextArea.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  error: PropTypes.string,
  type: PropTypes.string,
  hint: PropTypes.string,
  touched: PropTypes.bool,
  required: PropTypes.bool,
  warning: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object,
  classes: PropTypes.string
};

TextArea.defaultProps = {
  placeholder: null,
  label: null
}

export default TextArea;

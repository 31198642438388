// NPM Requirements
import _ from 'lodash';

// Misc Requirements
import API from '../modules/api';
import baseActionsFor from './baseActions';

// Create Base Actions
const baseActions = baseActionsFor('services', 'service');

// Custom Actions and Base Action Overrides
const customActions = {
  onServicesFormSubmit(data) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const promise = API.post(`/forms/services`, data);

      promise.then((response) => {
        dispatch(baseActions.updateSuccess(response));
      });

      promise.catch((response) => {
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },
};

// Merge Actions
const actions = _.assign(baseActions, customActions);

// Export
export default actions;
